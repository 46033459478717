@import url('https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    /* Prevent scrolling */
}

.under-construction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Centers content vertically */
    align-items: center;
    /* Centers content horizontally */
    height: 100vh;
    /* Full viewport height */
    width: 100vw;
    /* Full viewport width */
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    padding: 0;
    margin: 0;
    overflow: hidden;
    /* Prevent any content overflow */
}

.content {
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    margin-bottom: 0;
}

.aryo-text {
    font-family: 'Protest Guerrilla', sans-serif;
    font-size: clamp(3rem, 15vw, 8rem);
    /* Responsive font size */
    line-height: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.max-width-md {
    max-width: 540px;
}

a {
    color: #FFD700;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

a:hover {
    color: #FFA500;
}

/* For smaller screens, adjust font size */
@media (max-width: 576px) {
    .aryo-text {
        font-size: clamp(3rem, 15vw, 8rem);
    }
}